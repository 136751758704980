import React from "react"
import PropTypes from "prop-types"

import { footer } from "./footer.module.css"

const Footer = ({ company, children }) => (
  <footer className={footer}>
    <a href="mailto:hello@busterlabs.xyz">Contact Us</a>
    {children}
    <span>
      © {new Date().getFullYear()}
      {` `}
      {company}
    </span>
  </footer>
)

Footer.propTypes = {
  company: PropTypes.string,
  children: PropTypes.node,
}

Footer.defaultProps = {
  company: ``,
  children: null,
}

export default Footer
