/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Footer from "./footer"

const Layout = ({
  bodyClassName,
  contentClassName,
  children,
  footerChildren,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div className={bodyClassName}>
        <div className={contentClassName}>
          <main>{children}</main>
        </div>
        <Footer company="Buster Labs LLC">{footerChildren}</Footer>
      </div>
    )}
  />
)

Layout.propTypes = {
  bodyClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  footerChildren: PropTypes.node,
}

Layout.defaultProps = {
  bodyClassName: ``,
  contentClassName: ``,
  footerChildren: null,
}

export default Layout
