/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import appleTouchIcon from "../images/apple-touch-icon.png"
import favicon32 from "../images/favicon-32x32.png"
import favicon16 from "../images/favicon-16x16.png"
import favicon from "../images/favicon.ico"

function SEO({
  description,
  lang,
  itemScope,
  itemType,
  meta,
  keywords,
  title,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
        itemScope,
        itemType,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <link rel="icon" type="image/png" href={favicon} />
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": ["SoftwareApplication", "MobileApplication"],
          "name": "Intervals Pro: Tabata & HIIT ",
          "operatingSystem": ["IOS", "OSX"],
          "applicationCategory": "SportsApplication",
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.7",
            "ratingCount": "623"
          },
          "offers": {
            "@type": "Offer",
            "price": "0.00",
            "priceCurrency": "USD"
          }
        }
      `}
      </script>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-XH5ND26Q7D"
      ></script>
      <script
        key="plugin-google-tagmanager"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [{ platform: 'gatsby' }];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-XH5ND26Q7D');`,
        }}
      />
      ,
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  itemScope: ``,
  itemType: `https://schema.org/FAQPage`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  itemScope: PropTypes.string,
  itemType: PropTypes.string,

  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default SEO
